import { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'
import {
  requestClearDatas,
  requestDownload2ndRound,
  requestDownloadElectionDatas,
  requestDownloadErrors,
  requestExport,
  requestImport,
} from './services'
import { setError, setSuccess, setPercentage, setStatusWording } from './redux'
import { ELECTION_ROUND } from '../../utils/constants/ElectionConstants'

export const useImport = () => {
  const dispatch = useAppDispatch()

  const tPercentage = useRef<number>(0)
  const timer = useRef<NodeJS.Timer | null>()
  const type = useAppSelector((state) => state.configuration.type)
  const error = useAppSelector((state) => state.files.error)
  const isWarn = useAppSelector((state) => state.files.isWarn)
  const status = useAppSelector((state) => state.files.status)
  const success = useAppSelector((state) => state.files.success)
  const percentage = useAppSelector((state) => state.files.percentage)
  const statusWording = useAppSelector((state) => state.files.statusWording)
  const is2ndRound = useAppSelector(
    (state) => state.configuration.config?.currentRound === ELECTION_ROUND.second
  )

  const resetError = useCallback(() => dispatch(setError(null)), [dispatch])
  const resetSuccess = useCallback(() => dispatch(setSuccess(null)), [dispatch])
  const setPercentageValue = useCallback((value) => dispatch(setPercentage(value)), [dispatch])
  const setWordingValue = useCallback((value) => dispatch(setStatusWording(value)), [dispatch])

  // Import Handler
  useEffect(() => {
    if (!!success || !!error) {
      tPercentage.current = 0
      timer.current && clearInterval(timer.current)
    }
  }, [error, success])

  const onUploadProgress = useCallback(
    (payload) => {
      // 25% is file upload
      const perc = Math.ceil((payload.loaded * 25) / payload.total)
      if (perc > tPercentage.current) {
        setPercentageValue(perc)
        tPercentage.current = perc
        setWordingValue('import_upload')
      }
    },
    [setPercentageValue, setWordingValue]
  )

  const statusHandler = useCallback(() => {
    // 75% is file treatment
    timer.current = setInterval(async () => {
      const rStatus: any = await getAxios('/imports/status', getTokenHeaders())

      if (rStatus.error) {
        return timer.current && clearInterval(timer.current)
      }

      if (typeof rStatus.data !== 'object') {
        return
      }

      const { stepIndex, totalSteps, stepName } = rStatus.data
      if (stepIndex >= totalSteps) {
        setPercentageValue(100)
        tPercentage.current = 100
        setWordingValue('import_completed')

        return timer.current && clearInterval(timer.current)
      }

      setWordingValue(stepName)
      setPercentageValue(25 + (Math.ceil((stepIndex * 75) / totalSteps) || 0))
    }, 1000)

    return () => timer.current && clearInterval(timer.current)
  }, [setPercentageValue, setWordingValue])

  const downloadErrors = useCallback(() => dispatch(requestDownloadErrors()), [dispatch])
  const importFiles = useCallback(
    (excelFile, zipFile) => {
      statusHandler()
      dispatch(requestImport({ files: { excelFile, zipFile }, onUploadProgress, is2ndRound }))
    },
    [dispatch, is2ndRound, onUploadProgress, statusHandler]
  )

  const downloadElectionDatas = useCallback(
    (type) => {
      dispatch(requestDownloadElectionDatas(type))
    },
    [dispatch]
  )

  return {
    error,
    isWarn,
    status,
    success,
    percentage,
    statusWording,
    importFiles,
    downloadElectionDatas,
    resetError,
    downloadErrors,
    resetSuccess,
  }
}

export const useExport = (content) => {
  const dispatch = useAppDispatch()

  const error = useAppSelector((state) => state.files.error)
  const status = useAppSelector((state) => state.files.status)
  const success = useAppSelector((state) => state.files.success)

  const exportContent = useCallback(
    (filters) => dispatch(requestExport({ content, filters })),
    [content, dispatch]
  )

  const resetError = useCallback(() => dispatch(setError(null)), [dispatch])
  const resetSuccess = useCallback(() => dispatch(setSuccess(null)), [dispatch])

  return {
    error,
    status,
    success,
    exportContent,
    resetError,
    resetSuccess,
  }
}

export const useDatasDelete = () => {
  const dispatch = useAppDispatch()
  const deleteDatas = useCallback(() => dispatch(requestClearDatas()), [dispatch])

  return deleteDatas
}

export const use2ndRoundExport = () => {
  const dispatch = useAppDispatch()
  const download2ndRoundFiles = useCallback(() => dispatch(requestDownload2ndRound()), [dispatch])
  return download2ndRoundFiles
}
